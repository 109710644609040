// ■コンポーネント説明
// ラベル

import React from 'react';

export interface UILabelProps {
  /**
   * ラベル内に表示するテキスト。
   */
  text?: string;
  /**
   * ラベルの種類・区分。
   */
  section?: string;
}

const labelDiscrimination = function f(section: string) {
  switch (section) {
    case `blue`: {
      return `tag tag--blue`;
    }
    case `green`: {
      return `tag tag--green`;
    }
    case `pdf`: {
      return `tag-pdf`;
    }
    default: {
      return `news_tag`;
    }
  }
};

const UILabel: React.FC<UILabelProps> = (props) => {
  const { text, section } = props;

  return (
    <>
      <div className={labelDiscrimination(section)}>{text}</div>
    </>
  );
};

UILabel.defaultProps = {
  text: `text`,
};

export default UILabel;
