import React, { useContext, useEffect } from 'react';
import { Link, PageProps, graphql } from 'gatsby';
import SEO from '@/components/seo';
import CDNReplace from '@/components/CDNReplace';
import UIBreadcrumb from '@/components/UI/UIBreadcrumb';
import hoverCircle from '@/components/Interaction/hoverCircle';
import UILabel from '@/components/UI/UILabel';
import * as Util from '@/components/Util';
import { Context } from '@/components/Layout';

import * as pageHeaderCategory from '@/styles/object/page-header-category.module.scss';

const BooksIndex: React.FC<PageProps<GatsbyTypes.BooksIndexQuery>> = ({
  data,
}) => {
  const loading = useContext(Context);
  useEffect(() => {
    hoverCircle();
  }, []);

  return (
    !loading && (
      <>
        <SEO title="書籍紹介" />
        <div className="page-wrap --common">
          <div className="page-wrap__wave" />
          <div className="page-wrap__bg" />
          <header className={pageHeaderCategory.page_header_category}>
            <h1>
              <small
                className={pageHeaderCategory.page_header_category__head_text}
              >
                書籍紹介
              </small>
              <div className={pageHeaderCategory.page_header_category__text}>
                Books
              </div>
            </h1>
            <p className={pageHeaderCategory.page_header_category__lead}>
              NRIネットコム社員が執筆に携わった書籍をご紹介します。
            </p>
          </header>
          <div className="page-contents-box">
            <section className="section">
              <div className="box-3rows-02 --tab-rows">
                {data.allMicrocmsBooks.edges.map(({ node }) => (
                  <Link
                    key={node.booksId}
                    to={`/books/${node.booksId}/`}
                    className="box-3rows-02__item item-card-02 js-hover-circle"
                    data-circle-type=""
                    data-circle-inner="arrow-right"
                  >
                    <figure className="item-card-02__fig">
                      <img
                        loading="lazy"
                        src={`${CDNReplace(
                          node.image.url,
                        )}?w=320&pad-top=20&fill=fill&fit=crop&fill-color=d9e2e6&h=180&blend-x=40&blend-fit=scale&bg=d9e2e6&trim=auto&crop=top&pad-left=90&pad-right=90&q=75`}
                        alt=""
                      />
                    </figure>
                    <div className="item-card-02__main">
                      <div className="item-card-02__tag">
                        {node.tag.map(({ tag, id }) => (
                          <UILabel key={id} text={tag} />
                        ))}
                      </div>
                      <h4 className="item-card-02__title">{node.title}</h4>
                      <p className="item-card-02__text">
                        {Util.concatTxt(Util.deleteTag(node.content), 63)}
                      </p>
                    </div>
                  </Link>
                ))}
              </div>
            </section>
          </div>
        </div>
        <UIBreadcrumb UpperItem={[[`Works`, `/works`]]} CurrentItem="Books" />
      </>
    )
  );
};

export default BooksIndex;

export const query = graphql`
  query BooksIndex {
    allMicrocmsBooks {
      edges {
        node {
          id
          booksId
          title
          content
          authors {
            employee
            name
          }
          image {
            url
            height
            width
          }
          tag {
            tag
            id
          }
          store {
            seven
            amazon
            rakuten
          }
          publisher
          issuedDate(formatString: "YYYY年MM月")
          nominalCost
        }
      }
    }
  }
`;
